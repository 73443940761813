import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amway-acc/acc-mixins/l10n";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import defaultContent from "../../../../content/bfiConsistencyPaymentMetric";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconAdd } from "../../../../common/icons/IconAdd/IconAdd";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import "./BFIConsistencyPaymentMetric.scss";
import {
  useGetBronzeConsistencyPaymentQuery,
  useGetBronzeEarningQuery,
} from "../../../../services/bronzeIncentivesAPI";
import { useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { IconSchedule } from "../../../../common/icons/IconSchedule/IconSchedule";
import BronzeConsistencyPaymentDetails from "../../BronzeCommon/BronzeConsistencyPaymentDetails/BronzeConsistencyPaymentDetails";

type Props = {
  awardCode: number;
  isFixedPayment: boolean;
  fixedPaymentAwardCode: number;
  show?: boolean;
};

const BFIConsistencyPaymentMetric = ({ awardCode, isFixedPayment, fixedPaymentAwardCode, show = true }: Props) => {
  const { t } = useTranslation(["bfiIncentiveProgress"]);
  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const [showModal, setShowModal] = useState(false);

  const affAbo = getLoginAffAbo({ boot });
  const { isoCountryCode = "" } = boot?.user || {};

  const { data: bronzeEarningData, isFetching: isEarningDataFetching } = useGetBronzeEarningQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
  });

  const { data: bronzeConsistencyPaymentData, isFetching: isConsistencyDataFetching } =
    useGetBronzeConsistencyPaymentQuery(
      fixedPaymentAwardCode === -1
        ? skipToken // Skip the query when fixedPaymentAwardCode is -1
        : {
            affAbo,
            period: selectedPeriod,
            awardCode: fixedPaymentAwardCode,
            countryCode: isoCountryCode,
            locale,
          },
    );

  const { bonus = { amount: 0, isoCurrency: "", amountCurrency: "" } } = bronzeEarningData || {};

  const {
    bonusAmount = 0,
    formattedBonusAmount = "",
    target = 0,
    met = false,
    schedule = [],
  } = bronzeConsistencyPaymentData || {};

  const isLoading = isEarningDataFetching === true || isConsistencyDataFetching === true;

  const getTotal = () => {
    const total = bonus.amount + bonusAmount;
    return formatCurrency(total, bonus.isoCurrency, locale, isoCountryCode, {
      style: "currency",
    });
  };

  const onCallback = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  if (show === true && isFixedPayment === true && met === true && bonus.amount > 0)
    return (
      <div className="bfi-consistency-payment-metric">
        <div className="bfi-consistency-payment-metric__content">
          <div className="bfi-consistency-payment-metric__incentive-calculation">
            <IncentiveCalculation
              isLoading={isLoading}
              base={{
                title: t("base", defaultContent["base"]),
                amount: bonus.amountCurrency,
                icon: <IconSchedule />,
                iconAriaLabel: `${t("iconAriaLabel", defaultContent["iconAriaLabel"])}`,
                callbackFn: onCallback,
                hideDesktopIcon: true,
              }}
              multiplier={{
                title: t("multiplier", defaultContent["multiplier"]),
                amount: formattedBonusAmount,
                icon: <IconSchedule />,
                iconAriaLabel: `${t("iconAriaLabel", defaultContent["iconAriaLabel"])}`,
                callbackFn: onCallback,
                symbolIcon: <IconAdd />,
                hideMobileIcon: true,
              }}
              total={{
                title: t("currentTotal", defaultContent["currentTotal"]),
                amount: getTotal(),
                symbolIcon: <IconEquals />,
              }}
            />
          </div>
        </div>
        <BronzeConsistencyPaymentDetails
          showModal={showModal}
          onModalClose={onModalClose}
          module={"bfi"}
          schedules={schedule}
          targetRequired={target}
          met={met}
        />
      </div>
    );

  return <></>;
};
export default BFIConsistencyPaymentMetric;
