import { useTranslation } from "react-i18next";
import { useEffect, useState, Suspense } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import defaultContent from "../../content/bronzeIncentives";
import "./BronzeIncentives.scss";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import LeafRectangleLoader from "../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import { ROUTE_PATHS } from "../../common/enums/routing-enums";
import BronzeIncentivesNavigation from "./BronzeCommon/BronzeIncentivesNavigation/BronzeIncentivesNavigation";
import BronzeIncentivesViews, { BronzeView } from "./BronzeCommon/BronzeIncentivesViews/BronzeIncentivesViews";
import BronzeBannerToast from "./BronzeBannerToast/BronzeBannerToast";
import BIEligibilitySlider from "./BronzeCommon/BIEligibilitySlider/BIEligibilitySlider";

export const BronzeIncentiveContainer = () => {
  const RouterLoader = () => {
    return (
      <div className="bronze-incentives-container__router-loader">
        <div className="bronze-incentives-container__router-loader__header">
          <LeafRectangleLoader isLoading={true} numLines={1} width="50%" height="3rem" />
          <LeafRectangleLoader isLoading={true} numLines={1} width="80%" height="2rem" />
        </div>
        <div className="bronze-incentives-container__router-loader__row bronze-incentives-container__router-loader__first">
          <div className="bronze-incentives-container__router-loader__row__box">
            <LeafTile isLoading={true} title={" "} />
          </div>
          <div className="bronze-incentives-container__router-loader__row__box">
            <LeafTile isLoading={true} title={" "} />
          </div>
          <div className="bronze-incentives-container__router-loader__row__box">
            <LeafTile isLoading={true} title={" "} />
          </div>
        </div>
        <div className="bronze-incentives-container__router-loader__header">
          <LeafRectangleLoader isLoading={true} numLines={1} width="18.75rem" height="1.5rem" />
        </div>
        <div className="bronze-incentives-container__router-loader__row bronze-incentives-container__router-loader__second">
          <div className="bronze-incentives-container__router-loader__row__box">
            <LeafTile isLoading={true} title={" "} />
          </div>
          <div className="bronze-incentives-container__router-loader__row__box">
            <LeafTile isLoading={true} title={" "} />
          </div>
          <div className="bronze-incentives-container__router-loader__row__box">
            <LeafTile isLoading={true} title={" "} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="bronze-incentives-container">
      <Suspense fallback={<RouterLoader />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

const BronzeIncentives = () => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { hash, search } = useLocation();
  const navigate = useNavigate();

  const [bronzeViews, setBronzeViews] = useState<BronzeView[]>([]);

  const initialBronzeViews = BronzeIncentivesViews();
  if (initialBronzeViews.length > 0 && bronzeViews.length < 1) {
    setBronzeViews(initialBronzeViews);
  }

  const selectedView = bronzeViews.find((view) => view.selected === true);

  // todo: remove this block after BronzeIncentives page went live
  const queryParams = new URLSearchParams(search);
  const showOldBI = queryParams.get("showOldBI") === "true";

  const updateViews = (viewId: string) => {
    const updatedViews = bronzeViews.map((view: BronzeView) => ({
      ...view,
      selected: view.id === viewId,
    }));
    setBronzeViews(updatedViews);
  };

  const navButtonOnClick = (viewId: string) => {
    window.location.hash = viewId;
    updateViews(viewId);
  };

  useEffect(() => {
    if (hash) {
      const viewId = hash.substring(1);
      updateViews(viewId);
    } else if (selectedView) {
      window.location.hash = selectedView.id;
    }

    // todo: remove this block after BronzeIncentives page went live
    if (showOldBI === true && hash) {
      const viewId = hash.substring(1);
      switch (viewId) {
        case "pathtobronze":
          navigate(ROUTE_PATHS.PATH_TO_BRONZE);
          break;
        case "bronzefoundation":
          navigate(ROUTE_PATHS.BRONZE_FOUNDATION);
          break;
        case "bronzebuilder":
          navigate(ROUTE_PATHS.BRONZE_BUILDER);
          break;
      }
    }
  }, []);

  return (
    <div className="bronze-incentives">
      <BronzeBannerToast />
      <div className="bronze-incentives__header">
        <h1>{selectedView ? t(selectedView.id, defaultContent[selectedView.id]) : ""}</h1>
        <div className="bronze-incentives__date">
          <TimeStamp dateFormat={"long"} />
        </div>
      </div>

      <BronzeIncentivesNavigation bronzeViews={bronzeViews} navButtonOnClick={navButtonOnClick} />

      <div className="bronze-incentives__viwes">{selectedView && selectedView.BronzeView}</div>
      <BonusInfoFooter />
      <BIEligibilitySlider />
    </div>
  );
};

export default BronzeIncentives;
