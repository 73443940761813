import { countryDisplayName } from "@amway-acc/acc-mixins/l10n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LeafCard } from "../../../common/leaf/LeafCard/LeafCard";
import { fetchConfig, storedSsoOrigin } from "../../../common/util/config";
import { storedLocale } from "../util";
import "./AccountSelector.scss";
import defaultContent from "../../../content/accountSelector";

export type BusinessAccount = {
  isoCountryCode: string;
  abo: string;
  affiliate: string;
};

export const AccountSelector = () => {
  const { t } = useTranslation(["accountSelector"]);
  const [availableAccounts, setAvailableAccounts] = useState<BusinessAccount[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const encodedAccounts = new URLSearchParams(window.location.search).get("businesses");
    fetchConfig().then((config) => {
      const { launchedMarkets } = config;
      const allAccounts = encodedAccounts ? JSON.parse(decodeURIComponent(encodedAccounts)) : [];
      const availableAccounts = allAccounts.filter((b: BusinessAccount) => {
        return launchedMarkets.indexOf(b.isoCountryCode) !== -1;
      });
      setAvailableAccounts(availableAccounts);
    });
  }, []);

  const redirectAmwayIdLogin = (account: BusinessAccount) => {
    navigate(
      `/amway-id-login.html?aff=${account.affiliate}&abo=${
        account.abo
      }&ssoOrigin=${storedSsoOrigin()}&locale=${storedLocale()}`,
    );
  };

  const renderAccount = (account: BusinessAccount) => {
    return (
      <LeafCard
        abo={account.abo}
        country={countryDisplayName(storedLocale(), account.isoCountryCode)}
        clickAction={() => redirectAmwayIdLogin(account)}
        key={`${account.affiliate}-${account.abo}`}
        id={`${account.affiliate}-${account.abo}`}
      ></LeafCard>
    );
  };

  return (
    <div>
      <h2 className="account-selector__title">{t("pageTitle", defaultContent["pageTitle"])}</h2>
      <div className="account-selector__container">
        <div className="account-selector__header">{t("pleaseSelect", defaultContent["pleaseSelect"])}:</div>
        <div>{availableAccounts.map((account) => renderAccount(account))}</div>
      </div>
    </div>
  );
};
