import { useReducer, useState } from "react";
import { ImpersonationDetails } from "../../common/interfaces/impersonation";
import { IconUser } from "../../common/icons/IconUser/IconUser";
import { LeafSearchDropdown } from "../../common/leaf/LeafSearchDropdown/LeafSearchDropdown";
import { AFF_MARKET_MAP } from "../../common/constants/aff-market-map";
import { LeafToggleSwitch } from "../../common/leaf/LeafToggleSwitch/LeafToggleSwitch";
import { authorization, affiliates } from "./impersonation-util";
import "./impersonation.scss";
import { localeCodes } from "./locale-codes";
import { ImpersonationHistory } from "./impersonation-history/impersonation-history";

const formReducer = (state: any, event: any) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

export const Impersonation = () => {
  const [formData, setFormData] = useReducer(formReducer, {
    abo: 0,
    country: 0,
    partyId: "12345",
    "is-jwt": true,
    locale: "en-US",
    note: "",
  });

  const createLocaleMap = () => {
    const localeMap: { [key: string]: string } = {};
    localeCodes
      .slice()
      .sort((a, b) => (a.code > b.code ? 1 : -1))
      .map((locale) => (localeMap[locale.code] = `(${locale.code}) - ${locale.locale}`));
    return localeMap;
  };

  const [historyImpn, setHistoryImpn] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [showPartyId, setShowPartyId] = useState(true);

  const handleSubmit = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    authorization(formData);
    const historyItem = {
      abo: formData.abo,
      country: formData.country,
      locale: formData.locale,
      note: formData.note,
      partyId: formData.partyId,
      spcode: "",
    };
    setHistoryImpn(historyItem);

    setTimeout(() => {
      setSubmitting(false);
      setFormData({
        reset: true,
      });
    }, 1000);
  };

  const impersonateHistory = (impersonationDetails: ImpersonationDetails) => {
    authorization({ ...impersonationDetails, locale: formData.locale });
  };

  const handleChange = (event: any) => {
    const isJwtCheckboxEvent = event.target.type === "checkbox";
    if (isJwtCheckboxEvent) {
      const isJwtChecked = event.target.checked;
      setShowPartyId(isJwtChecked);
      setFormData({
        name: "partyId",
        value: isJwtChecked ? "12345" : "",
      });
    }
    const eventValue = event.target.name === "partyId" ? event.target.value.toString().slice(0, 9) : event.target.value;
    setFormData({
      name: event.target.name,
      value: isJwtCheckboxEvent ? event.target.checked : eventValue,
    });
  };

  const checkDefaultFields = () => {
    if (formData.abo > 0 && formData.country > 0 && formData.locale) {
      return true;
    }
    return false;
  };

  const renderCountryList = () => {
    return affiliates
      .sort((a, b) => (a.label > b.label ? 1 : -1))
      .map((aff, i) => {
        return (
          <div key={i}>
            {" "}
            {aff.label} - {aff.iso} - {aff.country}
          </div>
        );
      });
  };

  const isInValid = (): boolean => {
    if (formData["is-jwt"]) {
      if (checkDefaultFields() && formData.partyId) {
        return false;
      } else {
        return true;
      }
    } else {
      return !checkDefaultFields();
    }
  };

  const setCountry = (aff: string) => {
    setFormData({ name: "country", value: aff });
  };

  const setLocale = (locale: string) => {
    setFormData({ name: "locale", value: locale });
  };

  return (
    <div className="impersonation__container">
      <div className="impersonation">
        <h1 className="impersonation__heading">MyBiz Impersonation</h1>
        <div className="impersonation__form">
          <div className="impersonation__jwtToggle">
            <LeafToggleSwitch
              isToggled={formData["is-jwt"] || false}
              onToggle={handleChange}
              name="is-jwt"
              label="Use JWT Auth"
            />
          </div>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={submitting}>
              <div className="impersonation__formElements">
                <div className="impersonation__inputGroup">
                  <label>ABO #</label>
                  <input
                    className="impersonation__input"
                    id="impersonation__abo"
                    type="number"
                    name="abo"
                    min={0}
                    onChange={handleChange}
                    value={formData.abo || ""}
                  />
                </div>
                <div className="impersonation__inputGroup">
                  <label>Country Code</label>
                  <div className="impersonation__searchDropdown">
                    <LeafSearchDropdown
                      handleSelect={setCountry}
                      optionsMap={AFF_MARKET_MAP}
                      placeholder="Search aff or country code"
                    />
                  </div>
                </div>
                <div className={`impersonation__inputGroup ${!showPartyId ? "impersonation__disabled" : ""}`}>
                  <label className={!showPartyId ? "impersonation__disabled" : ""}>Party ID</label>
                  <input
                    className="impersonation__input"
                    id="impersonation__party-id"
                    name="partyId"
                    type="number"
                    min={0}
                    onChange={handleChange}
                    value={formData.partyId || ""}
                    disabled={!showPartyId}
                  />
                </div>

                <div className="impersonation__inputGroup">
                  <label>Locale</label>
                  <div className="impersonation__searchDropdown">
                    <LeafSearchDropdown
                      handleSelect={setLocale}
                      optionsMap={createLocaleMap()}
                      placeholder="Search Locale"
                      selectedValue={formData.locale || ""}
                    />
                  </div>
                </div>
                <div className="impersonation__inputGroup impersonation__inputNote">
                  <label>Note (optional)</label>
                  <input
                    className="impersonation__input"
                    id="impersonation__note"
                    name="note"
                    onChange={handleChange}
                    value={formData.note || ""}
                  />
                </div>
                <div className="impersonation__inputGroup impersonation__inputSubmitGroup">
                  <button
                    id="impersonation"
                    className="impersonation__submitButton"
                    type="submit"
                    disabled={isInValid()}
                  >
                    <IconUser style={"filled"} color={"white"} />
                    <span>Impersonate</span>
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <h2 className="impersonation__section-header impersonation__section-header--recently-used">Recently Used</h2>
        <div className="impersonation__recently-used">
          <ImpersonationHistory
            impersonationAdded={historyImpn}
            impersonationAuthCallback={impersonateHistory}
          ></ImpersonationHistory>
        </div>

        <h2 className="impersonation__section-header">Country Codes</h2>
        <div className="impersonation__country-list">
          <div id="impersonation__country-list-list" className="impersonation__country-list-list">
            {renderCountryList()}
          </div>
        </div>
      </div>
    </div>
  );
};
