import "./RequirementTrackingTile.scss";
import { Trans, useTranslation } from "react-i18next";
import { performanceYear } from "@amway-acc/acc-mixins/dates";
import { useState } from "react";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { LeafProgressBar } from "../../../common/leaf/LeafProgressBar/LeafProgressBar";
import defaultContent from "../../../content/ttci";
import LeafButton from "../../../common/leaf/LeafButton/LeafButton";
import LeafTag from "../../../common/leaf/LeafTag/LeafTag";
import LeafHalfCircle from "../../../common/leaf/LeafProgressHalfCircle/LeafProgressHalfCircle";
import { TargetScheduleModal } from "../TargetScheduleModal/TargetScheduleModal";
import TEALIUM from "../../../common/enums/tealium-enums";
import { getPerformanceYearShort } from "../../../common/util/period";

interface requirementProps {
  isLoading: boolean;
  currentValue: number;
  maxValue?: number;
  targetValue: number;
  shouldShowGraphics: boolean;
  period: string;
  requirementType: "ppv" | "vcs";
  vcsCumulative: number;
  ppvCumulative: number;
}

export const RequirementTrackingTile = ({
  isLoading,
  currentValue,
  maxValue = 100,
  targetValue,
  shouldShowGraphics,
  period,
  requirementType,
  vcsCumulative,
  ppvCumulative,
}: requirementProps) => {
  const { t } = useTranslation(["ttci"]);
  const pyShort = getPerformanceYearShort(performanceYear(period).toString());
  const PY = `PY${pyShort}`;
  const isPPV = requirementType === "ppv";
  const isEarning = currentValue >= targetValue;
  let remaining: number | string;
  if (targetValue - currentValue > 0) {
    if (isPPV) {
      remaining = (targetValue - currentValue).toFixed(2);
    } else {
      remaining = targetValue - currentValue;
    }
  } else {
    remaining = 0;
  }

  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  const handleViewScheduleClick = () => {
    setShowModal(true);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.TWO_TIME_CASH_INCENTIVE,
      "",
      "view_schedule",
    );
  };

  const tileKey = isPPV ? "ppvTile" : "vcsTile";

  const getTitle = () => {
    return t(`${tileKey}.title`, defaultContent[tileKey]["title"], { target: targetValue });
  };

  const getDescription = () => {
    return t(`${tileKey}.description`, defaultContent[tileKey]["description"], { required: targetValue, PY });
  };

  const ProgressTracker = () => {
    return isPPV ? (
      <LeafProgressBar
        current={currentValue}
        target={targetValue}
        currentLabel={`${t("ppvTile.progressBarCurrentLabel", defaultContent["ppvTile"]["progressBarCurrentLabel"], {
          currentPV: currentValue.toFixed(2),
        })}`}
        targetLabel={`${t("ppvTile.progressBarTargetLabel", defaultContent["ppvTile"]["progressBarTargetLabel"], {
          requiredPV: targetValue,
        })}`}
      />
    ) : (
      <div id="leaf-half-circle">
        <LeafHalfCircle
          current={currentValue}
          maxValue={maxValue}
          target={targetValue}
          leftValue={Math.trunc(vcsCumulative)}
          leftLabel={"vcs"}
          rightValue={Math.trunc(ppvCumulative)}
          rightLabel={"ppv"}
        ></LeafHalfCircle>
      </div>
    );
  };

  const getTagContent = () => {
    if (isEarning) {
      return t("tracking", defaultContent["tracking"]);
    } else if (isPPV) {
      return t("additionalPvNeeded", defaultContent["additionalPvNeeded"], { pvNeeded: remaining });
    } else {
      return t("additionalPercentNeeded", defaultContent["additionalPercentNeeded"], { percentNeeded: remaining });
    }
  };

  return (
    <LeafTile title={getTitle()} isLoading={isLoading} id={`ttci-req-tracking-tile-${requirementType}`}>
      <LeafTag
        hideTag={!shouldShowGraphics}
        classname="ttci-req-tracking-tile__tag"
        variant={isEarning ? "green" : "orange"}
      >
        <Trans>{getTagContent()}</Trans>
      </LeafTag>

      <div className="ttci-req-tracking-tile__progress-tracker" hidden={!shouldShowGraphics}>
        <ProgressTracker />
      </div>

      <section className="ttci-req-tracking-tile__description">{getDescription()}</section>

      {isPPV && shouldShowGraphics && (
        <LeafButton clickAction={handleViewScheduleClick} id="ppv-target-schedule-button">
          {`${t("ppvTile.viewScheduleBtnText", defaultContent["ppvTile"]["viewScheduleBtnText"])}`}
        </LeafButton>
      )}
      <TargetScheduleModal
        show={showModal}
        onCloseClick={hideModal}
        py={period}
        currentPPV={ppvCumulative}
        monthlyPPV={targetValue}
      />
    </LeafTile>
  );
};
