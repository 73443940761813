import { useDispatch } from "react-redux";
import { BootType, BootUser, setBootLoading } from "../../reducers/boot";
import { SetBootForCurrentBusiness } from "../../reducers/model/boot";
import { AboName, setAboName } from "../../reducers/aboName";

// Custom hook to handle cookie logic
export const useHandleCookie = (boot: BootType, locale: string | null = null, aboName: AboName) => {
  const dispatch = useDispatch();

  const setCookie = (user: BootUser, aboName: AboName) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 60 * 60 * 1000); // Set expiration to 1 hour
    const expiresString = `expires=${expires.toUTCString()};path=/`;
    const userCookie = `myBizUser=${encodeURIComponent(JSON.stringify(user))};${expiresString}`;
    const aboNameCookie = `aboName=${encodeURIComponent(JSON.stringify(aboName))};${expiresString}`;

    if (user) {
      document.cookie = userCookie;
      document.cookie = aboNameCookie;
    }
  };

  const getUserCookie = (): BootUser | null => {
    const cookieName = "myBizUser=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let cookie of cookieArray) {
      cookie = cookie.trim();
      if (cookie.startsWith(cookieName)) {
        const cookieString = cookie.substring(cookieName.length);
        try {
          return JSON.parse(cookieString) as BootUser;
        } catch (error) {
          console.error("Error parsing BootUser cookie JSON:", error);
          return null;
        }
      }
    }
    return null;
  };

  const getAboCookie = (): AboName | null => {
    const cookieName = "aboName=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let cookie of cookieArray) {
      cookie = cookie.trim();
      if (cookie.startsWith(cookieName)) {
        const cookieString = cookie.substring(cookieName.length);
        try {
          return JSON.parse(cookieString) as AboName;
        } catch (error) {
          console.error("Error parsing AboName cookie JSON:", error);
          return null;
        }
      }
    }
    return null;
  };

  const isEmptyObject = (obj: object): boolean => {
    return Object.keys(obj).length === 0;
  };

  const handleCookieLogic = () => {
    const user = getUserCookie();
    const abo = getAboCookie();

    if (!isEmptyObject(boot.configuration)) {
      if (!user) {
        setCookie(boot.user, aboName);
        return;
      } else if (user.abo !== boot.user.abo && user.access_token !== boot.user.access_token) {
        setCookie(boot.user, aboName);
        return;
      }
    } else {
      if (user && abo) {
        dispatch(setAboName(abo.name));
        dispatch(setBootLoading(true));
        SetBootForCurrentBusiness({ user });
      }
    }
  };

  return { handleCookieLogic };
};
