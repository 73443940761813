import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/bfiRequirements";
import { VolumeRequirementTile } from "../../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementVCSPV = (props: Props) => {
  const { t } = useTranslation(["bfiRequirements"]);

  const { met = false, required = false, actual = 0, target = 0, short = 0, isFetching } = props;

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <VolumeRequirementTile
      title={`${t("vcsPVTitle", defaultContent["vcsPVTitle"], {
        VCSPV: target,
      })}`}
      additionalNeeded={short}
      met={met}
      isLoading={isFetching}
      currentAmount={actual}
      targetAmount={target}
      circleCheckmarkId={"volumeRequirementTile__vcspv"}
      volumeType={"vcspv"}
    />
  );
};

export default BronzeRequirementVCSPV;
