import LeafIncentiveEligibilityBanner from "../../../../common/leaf/LeafIncentiveEligibilityBanner/LeafIncentiveEligibilityBanner";
import { showEligibilitySlider } from "../../../../reducers/bronzeIncentive";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { useGetBronzeEligibilityQuery } from "../../../../services/bronzeIncentivesAPI";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";

type Props = {
  awardCode: number;
  show?: boolean;
};

const BFIEligibilityBannerTile = ({ awardCode, show = true }: Props) => {
  const dispatch = useAppDispatch();

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });

  const { isFetching, isError, data } = useGetBronzeEligibilityQuery({
    period: selectedPeriod,
    affAbo,
    locale,
    awardCode,
  });

  const { eligible = false, graduated = false, remainingIncentiveEarnings = 0, endPeriod = 0 } = data || {};

  const eligibleButtonOnClick = () => {
    dispatch(showEligibilitySlider());
  };

  return (
    <LeafIncentiveEligibilityBanner
      module={"bfi"}
      graduated={graduated}
      eligible={eligible}
      remainingIncentiveEarnings={remainingIncentiveEarnings}
      endPeriod={`${endPeriod}`}
      eligibleButtonOnClick={eligibleButtonOnClick}
      isLoading={isFetching}
      show={show}
    />
  );
};
export default BFIEligibilityBannerTile;
