import { AWARD_CODES } from "../../../common/enums/award-codes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { useGetBronzeProgramStatusQuery } from "../../../services/bronzeIncentivesAPI";
import { updateProgramStatusDetails } from "../../../reducers/bronzeIncentive";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import BBIBannerTile from "./BBIBannerTile/BBIBannerTile";
import "./BronzeBuilder.scss";

const BronzeBuilder = () => {
  const dispatch = useAppDispatch();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });
  const { bronzeRestart = false } = boot?.configuration?.corePlus || {};

  const { data, isFetching } = useGetBronzeProgramStatusQuery({
    affAbo,
    period: selectedPeriod,
    primaryAwardCode: AWARD_CODES.BRONZE_BUILDER,
    fixedPayment: AWARD_CODES.FIXED_PAYMENT,
    restart1: bronzeRestart === true ? AWARD_CODES.RESTART_BRONZE_BUILDER : void 0,
    restart2: bronzeRestart === true ? AWARD_CODES.RESTART_TWO_BRONZE_BUILDER : void 0,
  });

  if (isFetching === false && data) {
    const { awardCode, isFixedPayment, fixedPaymentAwardCode } = data;

    dispatch(updateProgramStatusDetails(data));

    return (
      <div className="bronze-builder">
        <div className="bronze-builder__overview">
          <div className="bronze-builder__overview__banner">
            {/** todo: when only display banner, set compact view to false */}
            <BBIBannerTile
              awardCode={awardCode}
              isFixedPayment={isFixedPayment}
              isCompactView={true}
              fixedPaymentAwardCode={fixedPaymentAwardCode}
            />
          </div>
          <div className="bronze-builder__overview__tracking"></div>
          <div className="bronze-builder__overview__eligible"></div>
        </div>
      </div>
    );
  }
  return <LeafTile isLoading={isFetching} />;
};

export default BronzeBuilder;
