import { Trans, useTranslation } from "react-i18next";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../content/leadershipSeminar";
import "./LeadershipSeminar.scss";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { PQRequirementsType } from "../../common/interfaces/leadershipSeminar";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import PQRequirements from "./PQRequirements/PQRequirements";
import { LSIBanner } from "./LSIBanner/LSIBanner";

export default function LeadershipSeminar() {
  const { t } = useTranslation(["leadershipSeminar"]);

  const mockPQRequirementData: PQRequirementsType = {
    pqHistory: [
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202309",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202310",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202311",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202312",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202401",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202402",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202403",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202404",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202405",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202406",
      },
      {
        status: QUALIFICATION_STATUS.TRACKING,
        period: "202407",
      },
      {
        status: QUALIFICATION_STATUS.TRACKING,
        period: "202408",
      },
    ],
    totalPqs: 12,
    achievedPqs: 10,
    status: QUALIFICATION_STATUS.NOT_QUALIFIED,
  };

  return (
    <main className="LeadershipSeminar">
      <LSIBanner Status={QUALIFICATION_STATUS.TRACKING} isLoading={false} />
      <div className="LeadershipSeminar__pageTitle">
        <div className="LeadershipSeminar__pageTitle--title">
          <Trans>{`${t("pageTitle.title", defaultContent["pageTitle"]["title"])}`}</Trans>
        </div>
        <div className="LeadershipSeminar__pageTitle--timestamp">
          <TimeStamp dateFormat={"long"} />
        </div>
      </div>
      <div className="LeadershipSeminar__requirementsSection">
        <div className="LeadershipSeminar__requirementsSection--title">
          {`${t("requirements.sectionHeader", defaultContent["requirements"]["sectionHeader"])}`}
        </div>
        <div className="LeadershipSeminar__requirementsSection--description">
          <Trans>
            {t("requirements.description", defaultContent.requirements["description"], {
              current: "4",
              needed: "4",
            })}
            <span />
          </Trans>
        </div>
        <div className="LeadershipSeminar__requirementsSection--tileSection">
          <div className="LeadershipSeminar__requirementsSection--tile">
            <LeafTile>Tile 1</LeafTile>
          </div>
          <div className="LeadershipSeminar__requirementsSection--tile">
            <LeafTile>Tile 2</LeafTile>
          </div>
          <div className="LeadershipSeminar__requirementsSection--tile">
            <LeafTile>Tile 2</LeafTile>
          </div>
        </div>
        <div className="LeadershipSeminar__requirementsSection--pqTile">
          <PQRequirements pqRequirements={mockPQRequirementData} isLoading={false} showPQRequirementsTile={true} />
        </div>
      </div>
      <div className="LeadershipSeminar__footer">
        <BonusInfoFooter />
      </div>
    </main>
  );
}
